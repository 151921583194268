import React from "react";
import bg from './bg.jpg'
import './info-2.scss'

export default function Info2() {

    return <div className="info-2">
        <div className="info-2__column">
            <img src={bg} alt=""/>
        </div>
        <div className="info-2__column">
            <h4>APPLY FOR ADMISSION</h4>
            <h5>2021 Applications are now open</h5>
            <span>University admission and college admission is now open for individuals who wants learn pursue art courses on campus.</span>
            <div className="info-2__button button-teko">Apply now</div>
        </div>
    </div>
}