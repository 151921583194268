import React from "react";
import bg from './bg.jpg'
import './info.scss'

export default function Info() {
    return <div className="info">
        <div className="info__column">
            <h5>WHY THE YALE SCHOOL OF ART</h5>
            <p>The Yale School of Art is a graduate school that confers MFAs in a variety of topics. Such qualifications are a great way to enter into a large market and command a higher salary. This school is great for people who want to dive into the field of graphic design. They're going to teach you about the diverse group of skills required to be a successful designer.
                A successful designer must make sure that the design is on brand, be able to create a logo, understand layout and typography, and be able to do a wide range of skills from digital to traditional. That's why it's nice to have a wide base of knowledge.
            </p>
        </div>

        <div className="info__column">
            <img src={bg} alt=""/>
        </div>
    </div>
}