import React from "react";
import {Link} from "react-router-dom";
import './card.scss'

export default function Card({item}) {

    return <div className="card">
        <div className="card__image">
            <img src={item.image} alt=""/>
        </div>

        <div className="card__text-wrapper">
            <h5 className="card__title">{item.title}</h5>
            <h5 className="card__date">{item.date}</h5>
        </div>

        <Link to={item.link}>{item.textLink}</Link>
    </div>
}