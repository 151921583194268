import React from 'react';
import logo from './logo.svg'
import {Link} from "react-router-dom";
import './header.scss'

export default function Header() {

    return <div className="header container">
        <div className="header__logo">
            <svg>
                <use xlinkHref={logo + "#logo"}></use>
            </svg>
        </div>
            <ul className="header__navigation">
                <li className="header__link"><Link to={'/'}>Головна</Link></li>
                <li className="header__link"><Link to={'/about'}>Про нас</Link></li>
                <li className="header__link"><Link to={'/info'}>Структура</Link></li>
                <li className="header__link"><Link to={'/news'}>Новини</Link></li>
            </ul>
    </div>
}