import {HashRouter, Route, Routes} from "react-router-dom";
import Header from "./components/header/header";
import Home from "./pages/home";
import './misc.scss'

function App() {
  return (
      <HashRouter>
       <Header />
        <Routes>
          <Route path="/" element={<Home/>}/>
          {/*<Route path="/about" element={<About/>}/>*/}
        </Routes>

      </HashRouter>
  );
}

export default App;
