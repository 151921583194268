import React from "react";
import './home-card.scss'
import card1 from './card-1.jpg'
import card2 from './card-2.png'
import card3 from './card-3.png'
import Card from "../card/card";

export default function HomeCards() {

    const cards = [
        {image: card1, title: 'Callander and Newsletter', date: 'June 24,2021', link: '/', textLink: 'Read  about our operations '},
        {image: card2, title: 'School News', date: 'October 4,2021', link: '/', textLink: 'Read the latest news about our development '},
        {image: card3, title: 'Student Voice', date: 'October 20,2021', link: '/', textLink: 'what the student has to say and what they think....'},
    ]

    return <div className="home-card container">
        <h3>OUR  BLOG POST</h3>

        <div className="home-card__wrapper">
            {cards.map((item, index) => {
                return <Card item={item} key={index}/>
            })}
        </div>
    </div>
}