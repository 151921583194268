import React from "react";
import './main-block.scss'

export default function MainBlock() {

    return <div className="main-block">
        <h1>КЗО “ДБНРЦ №9” ДОР</h1>
        <span>"ДНІПРОПЕТРОВСЬКИЙ БАГАТОПРОФІЛЬНИЙ НАВЧАЛЬНО-РЕАБІЛІТАЦІЙНИЙ ЦЕНТР №9 "</span>
        <div className="main-block__button button-teko">Почали!</div>
    </div>
}