import React from "react";
import MainBlock from "../../components/main-block/main-block";
import HomeNavigation from "../../components/home-navigation/home-navigation";
import Info from "../../components/info/info";
import Info2 from "../../components/info-2/info-2";
import HomeCards from "../../components/home-cards/home-cards";

export default function Home() {

    return <>
        <MainBlock/>
        <HomeNavigation />
        <Info />
        <div className="grey">
            <Info2/>
            <HomeCards />
        </div>
    </>
}