import React from "react";
import {Link} from "react-router-dom";
import './home-navigation.scss'

export default function HomeNavigation() {

    return (
        <div className="home-navigation">
            <Link to={'/'} className="home-navigation__item home-navigation__item--1">
                <h5>University Life</h5>
                <span>Overall in here</span>
            </Link>

            <Link to={'/'} className="home-navigation__item home-navigation__item--2">
                <h5>Graduation</h5>
                <span>Get Degree</span>
            </Link>

            <Link to={'/'} className="home-navigation__item home-navigation__item--3">
                <h5>Athlectics</h5>
                <span>Sports Club</span>
            </Link>

            <Link to={'/'} className="home-navigation__item home-navigation__item--4">
                <h5>Social</h5>
                <span>Overall in here</span>
            </Link>
        </div>
    );
}